.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  color: unset !important;
}

.ant-col.ant-form-item-label {
  padding: 0 !important;
}

.ant-layout-sider-trigger {
  background: rgb(249 250 251 / 1) !important;
}

.ant-layout-sider-trigger:hover {
  background: #002140 !important;
}

.ant-layout-sider-trigger:hover span {
  color: #fff !important;
}

.ant-layout-sider-trigger span {
  color: black !important;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: transparent !important;
  font-weight: 400 !important;
  color: #0FA5EA;
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu {
  background-color: rgb(249 250 251 / 1) !important;
}

.ant-menu-item:hover {
  background-color: transparent !important;
  color: #0FA5EA !important;
}

.ant-menu-submenu-title {
  margin: 0 !important;
  width: 100% !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  border-right: 2px solid #1f84c4;
  border-radius: 0px !important;
  color: inherit;
}

.ant-tree-select-dropdown {
  min-width: 400px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-carousel .slick-dots li button {
  background: black !important;
}

.ant-card {
  border-radius: 3px;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}