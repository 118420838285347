@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./overrides.css");

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

body {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0px 0px 0.45em;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.01071em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
}

.border-bottom {
  border-bottom: 1px solid #E1E7EB;
}

.border-top {
  border-top: 1px solid #E1E7EB;
}

.border-right {
  border-right: 1px solid #E1E7EB;
}

.border-left {
  border-left: 1px solid #E1E7EB;
}

.search-input-border.ant-input-affix-wrapper {
  border-radius: 12px !important;
}